var site = site || {};
var generic = generic || {};

(function ($, Drupal) {
  'use strict';

  var siteEmailSignupV1 = {
    formatter: $('.site-email-signup-ca-v1'),
    is_mobile: typeof site.client.isMobile == 'undefined' ? '0' : site.client.isMobile
  };

  Drupal.behaviors.siteEmailSignupV1 = {
    get: function (key) {
      return siteEmailSignupV1[key];
    },

    set: function (key, val) {
      siteEmailSignupV1[key] = val;
    },

    showEmailSignupContents: function (thisFormatter) {
      var self = this;

      $(document).trigger('click');

      if (self.get('is_mobile') == '0') {
        thisFormatter.addClass('active');

        var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
        var siteContainer = $('.site-header').siblings('.site-container');

        dimOverlay.prependTo(siteContainer);

        thisFormatter.bind('clickoutside', function () {
          thisFormatter.removeClass('active').unbind('clickoutside');
          dimOverlay.remove();
          // user is closing the overlay after signing up so hide it
          self.hideGnavSignup(thisFormatter);
        });

        thisFormatter.find('.site-email-signup__contents-close').once().click(function (e) {
          e.preventDefault();
          thisFormatter.trigger('clickoutside');
        });
      }
    },

    hideGnavSignup: function (thisFormatter) {
      if (sessionStorage.getItem('userSignedUpGnav') == 1) {
        thisFormatter.addClass('hidden');
      }
    },

    setContentsOffset: function (thisFormatter) {
      var siteBag = $('.site-header .site-bag');
      var siteMyMac = $('.site-header .site-my-mac');
      var siteMyShades = $('.site-header .my-shades');
      var siteBagWidth,
        siteMyMacWidth,
        siteMyShadesWidth = 0;

      if (siteBag.length === 1) {
        siteBagWidth = siteBag.outerWidth(false);
      }
      if (siteMyMac.length === 1) {
        siteMyMacWidth = siteMyMac.outerWidth(false);
      }
      if (siteMyShades.length === 1) {
        siteMyShadesWidth = siteMyShades.outerWidth(false);
      }
    },

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);

        // For each instance of email signup field, listen for successful gnav
        // data results.
        // Moved here by Bloom to allow gnavdata.js to not be coupled to any
        // implementations of its data
        $(window).on('gnavdata_success', function (e, gNavData) {
          sessionStorage.email_address = '';
          if (!_.isNull(gNavData) && !_.isEmpty(gNavData.EMAIL_ADDRESS)) {
            sessionStorage.email_address = gNavData.EMAIL_ADDRESS;
          }

          if (!_.isEmpty(sessionStorage.email_address)) {
            var emailField = thisFormatter.find('.return-user-email-newsletter');

            emailField.val(sessionStorage.email_address);
          }
        });

        // hide signup if the user has already done it this session
        self.hideGnavSignup(thisFormatter);

        // show overlay on click.
        thisFormatter.find('.site-email-signup__link').once().click(function () {
          if (!thisFormatter.hasClass('active')) {
            self.showEmailSignupContents(thisFormatter);
          } else {
            thisFormatter.removeClass('active');
            thisFormatter.trigger('clickoutside');
          }

          return false;
          thisFormatter.trigger('clickoutside');
        });

        // set initial overlay offset
        self.setContentsOffset(thisFormatter);

        // show checkbox when mobile input is focused
        thisFormatter.find('.mobile-number').focus(function () {
          thisFormatter.find('.form-signup-sms-promotions').slideDown(300);
        });

        thisFormatter.find('.return-user-email-newsletter').focus(function () {
          thisFormatter.find('.form-signup-pc-email-promotions').slideDown(300);
        });

        // handle user submitting the email/sms form
        thisFormatter.find('form').submit(function (submitEvt) {
          submitEvt.preventDefault();

          var params = {};

          $.each(thisFormatter.find('form').serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });
          // console.log(params);

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if (thisFormatter.find('form').find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (result) {
              // var results = result.getData();
              sessionStorage.setItem('userSignedUpGnav', 1);
              thisFormatter.find('.site-email-signup__contents-thank-you').removeClass('hidden');
              thisFormatter.find('.site-email-signup__contents-form').addClass('hidden');
              thisFormatter.find('.site-email-signup__contents-title').addClass('hidden');
            },
            onFailure: function (result) {
              // console.log(result.getMessages());
              var $formNode = thisFormatter.find('form[name="email_sms_signup"]');
              var errorObjectsArray = result.getMessages();

              generic.showErrors(errorObjectsArray, null, $formNode);
            }
          });
        });

        // global trigger to show overlay
        $(window).on('window::show_gnav_email_signup', function () {
          if (!thisFormatter.hasClass('active')) {
            self.showEmailSignupContents(thisFormatter);
          }
        });

        // hide overlay on resize, adjust overlay offset
        $(window).resize(_.debounce(function () {
          self.setContentsOffset(thisFormatter);
          if (thisFormatter.hasClass('active')) {
            thisFormatter.trigger('clickoutside');
          }
        }, 50));
      });
    },

    attached: false
  };
})(jQuery, Drupal);
